<template>
  <div class="maintenance_message">
    <h2>Section en maintenance</h2>
    <p>Vous serez en mesure d'accèder à cet espace d'ici peu</p>
    <p>
      Merci pour votre fidélité, n'hésitez pas à nous contacter pour plus
      d'informations
    </p>
  </div>
</template>

<script>
export default {
  name: "Maintenance",
};
</script>
